import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoSrcURL from "../images/op.m4a"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div class="overflow-hidden">
      <video
        playsinline
        className="video-player"
        height="100%"
        width="100%"
        muted
        autoPlay="autoplay"
      >
        <source
          src={VideoSrcURL}
          type="video/mp4"
        />
      </video>
      <div className="p-6 sm:p-10 leading-8">
        <p className="font-bold">CondeHouse Style 2021 June Online</p>
        <p>CondeHouse Style 2021 was held online in June. New products and proposals were presented. 
2018 was the 50th anniversary of CondeHouse, and since then we’ve been working on ‘rebranding’, incorporating global themes that epitomise intelligent design. An outcome of this work has been the conception of eighteen ‘lifestyle scenes’, each of which embodies the comfortable living that is created by furniture fashioned from natural materials. We offer a total coordination package for living rooms, dining rooms, and also, workspaces, the demand for which is increasing.</p>
        <p>These designs will be distributed simultaneously in Japan and worldwide. You can look forward to it.</p>
        <div className="border-2">
          <p className="pt-5 text-base font-bold text-center">CondeHouse Style 2021 June Online Menu</p>
          <ul class="sm:grid sm:grid-cols-3  text-center">
            {/* <li className="flex-auto"><a>プレゼンテーション</a></li>
            <li className="flex-auto">|</li> */}
            <li className="flex-auto"><a href="#presentation">Presentation</a></li>
            <li className="flex-auto"><a href="#shoprefresh">Store Rejuvenation</a></li>
            <li className="flex-auto"><a href="#seatforest">Forest of Seats</a></li>
            <li className="flex-auto"><a href="#newproducts">New Collection</a></li>
            <li className="flex-auto"><a href="#factorytour">Virtual Factory Tour</a></li>
          </ul>
        </div>
      </div>

    </div>
    <div class="overflow-hidden" id="presentation"> 
      <div class="p-6 sm:p-10 shadow-2xl leading-8" data-sal="slide-up"  data-sal-delay="500"  data-sal-easing="ease">
        <p className="text-lg font-bold">Presentation</p>
        <p>Please begin your CondeHouse Style 2021 June Online experience with this movie. Our new CEO Someya will talk about the new model released in June and the renewed Asahikawa flagship Store.</p>
        <iframe width="100%" class="h-60 sm:h-96" src="https://www.youtube.com/embed/6jquHvw1xks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="overflow-hidden">
      <StaticImage
        src="../images/scene1.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />
      <div class="p-6 sm:p-10 leading-8" id="shoprefresh">
        <p className="text-lg font-bold">Shop Rejuvenation</p>
        <p>Our head office and Asahikawa stores have been rejuvenated with eighteen ‘lifestyle scenes’; spaces designed to express comfortable living. We would like to share this with you.</p>
        {/* scene pictures */}
        <div className="">
          <p>Entrance</p>
          <div className="sm:grid sm:grid-cols-3 sm:gap-2">
            <div className="overflow-hidden"><StaticImage
              src="../images/entrance1.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/entrance2.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
          </div>
          <p>3F Shop</p>
          {/* grid */}
          <div className="sm:grid sm:grid-cols-3 sm:gap-2 scenelink">
            <div className="overflow-hidden"><StaticImage
              src="../images/scene01.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            />
            <p className="text-right"><a target="_blank" href="https://youtu.be/-MYXDtbqnTc">North Noble Scene 01</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene02.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
             <p className="text-right"><a target="_blank" href="https://youtu.be/k_TPcRkzSlo">North Noble Scene 02</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene03.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
             <p className="text-right"><a target="_blank" href="https://youtu.be/ybECvuPNYu0">North Noble Scene 03</a></p> 

            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene04.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/-Ox6g_VPQdk">North Noble Scene 04</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene05.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/H47dx9TMLm0">North Noble Scene 05</a></p> 

            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene06.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/KePZFUmY0xc">North Noble Scene 06</a></p> 

            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene07.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            />              
            <p className="text-right"><a target="_blank" href="https://youtu.be/UyzSIDMWhqM">North Noble Scene 07</a></p> 

            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene08.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/LEJ9s_KdXqg">North Noble Scene 08</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene09.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/RW8Z8d1enY0">Simple & Natural Scene 09</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene10.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/SmxutsSYgrE">Simple & Natural Scene 10</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene11.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/7kliyGGh9TA">Simple & Natural Scene 11</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene12.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/7Ssj8UusDhU">Simple & Natural Scene 12</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene13.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/qjjiJP0uH1I">Simple & Natural Scene 13</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene14.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/NVWNW1dag58">Simple & Natural Scene 14</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene15.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            />
            <p className="text-right"><a target="_blank" href="https://youtu.be/5PgmQ0ep__4">Advanced Classic Scene 15</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene16.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/BOvzoXVo_n8">Advanced Classic Scene 16</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene17.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/cQJ2tfFg9as">Advanced Classic Scene 17</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene18.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/AGsRGB5YcDU">Advanced Classic Scene 18</a></p> 
            </div>
          </div>
          
        </div>
      </div>
      <div class="p-6 sm:p-10 shadow-2xl leading-8" data-sal="slide-up"  data-sal-delay="500"  data-sal-easing="ease">
        <iframe width='100%' height='480' src='https://my.matterport.com/show/?m=tQk4KDTkJAe' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>        
      </div>
      <div class="p-6 sm:p-10 shadow-2xl leading-8">
      <p>3F〈Laboratory/Gallery〉</p>
          <div className="sm:grid sm:grid-cols-3 sm:gap-2">
            <div className="overflow-hidden"><StaticImage
              src="../images/labo.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            
          </div>
          <p>2F〈Meeting Room/Chairman's Room〉</p>
          <div className="sm:grid sm:grid-cols-3 sm:gap-2">
            <div className="overflow-hidden"><StaticImage
              src="../images/2f2.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/2f1.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/2f3.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
          </div>
          <p>3F〈Workstyle〉</p>
          <div className="sm:grid sm:grid-cols-3 sm:gap-2">
            <div className="overflow-hidden"><StaticImage
              src="../images/3f1.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/3f2.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/3f3.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
          </div>
          <p>5F〈History Room〉</p>
          <div className="sm:grid sm:grid-cols-3 sm:gap-2">
            <div className="overflow-hidden"><StaticImage
              src="../images/history1.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/history2.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/history3.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
          </div>
      </div>
    </div>
    {/* Seat Forest */}
    <div class="overflow-hidden" id="seatforest">
      <StaticImage
          src="../images/ph01.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem` }}
          className="topimg magnify"
        />
        <div class="p-6 sm:p-10 shadow-2xl leading-8 sm:grid sm:grid-cols-11">
          <div className="col-span-8">
            <p className="text-lg font-bold">Installation〈Forest of Seats〉</p>
            <p>This is an installation where you can experience eight unique chairs selected from Conde House's iconic products. The frames are made in Hokkaido, painted in seven original colors, and upholstery is coordinated. We hope you will find the comfort you have been looking for in this forest.</p>
          </div>
          <div className="col-span-3 seatforestimg">
            <StaticImage
              src="../images/ph02.jpg"
              alt="CondeHouse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            />
          </div>
         
        </div>
      </div>
    {/* san dining section starts */}
    <div class="overflow-hidden" id="newproducts">
      <StaticImage
        src="../images/newmodel.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />
      <div class="p-6 sm:p-10 shadow-2xl leading-8" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease">
        <p className="text-lg font-bold">New Collection Debut</p>
        <p>The new collection includes ‘SAN Dining’, which has been fashioned from Hokkaido birch, a sturdy hardwood, along with a new storage series co‑developed with Möbel Toko, and a solid table with a built-in wiring system, suitable for workspaces.</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-2 sm:gap-5">
      <div className="overflow-hidden">
        <StaticImage
          src="../images/san.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
      </div>
      <div className="">
        <p className="font-bold text-base">SAN Dining <br/>Design: Michael Schneider</p>
        <p className="leading-8">SAN Dining features a sophisticated design by German designer Michael Schneider. Using local Hokkaido hardwood birch, the new chair can be used in a wide range of living spaces, from homes to retail offices.</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3 sm:gap-2">
      <div className="overflow-hidden"><StaticImage
        src="../images/san1.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/san2.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/san3.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
    </div>
    <p className="text-center"><a href="https://www.condehouse.co.jp/products/detail.php?product_id=713" target="_blank" className="ch-button shadow-lg">→ Detail</a></p>
    {/* san dining section ends */}
     {/* A+ section starts */}
     <div className="p-6 sm:p-10 sm:grid sm:grid-cols-2 sm:gap-5">
      <div className="overflow-hidden">
      <StaticImage
        src="../images/system.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem`, width: '100%' }}
        className="topimg magnify"
      />
      </div>
      <div className>
      <p className="font-bold text-base">Select Furniture A+ <br />Select Furniture A+（A PLus）</p>
        <p className="leading-8">This is the rebirth of System Furniture! New designs have been developed in collaboration with Möbel Toko. New specifications have led to the evolution of a range of furniture that can be manipulated to suit your preferences.</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3 sm:gap-2">
      <div className="overflow-hidden"><StaticImage
        src="../images/a1.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/a2.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/a3.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
    </div>
    <p className="text-center"><a href="https://www.condehouse.co.jp/news/condehouse-news/new-collection-debut/#link_Aplus" target="_blank" className="ch-button shadow-lg">→ Detail</a></p>
    {/* A+ section ends */}
    {/* One section starts */}
       <div className="p-6 sm:p-10 sm:grid sm:grid-cols-2 sm:gap-5">
      <div className="overflow-hidden">
      <StaticImage
        src="../images/onetop.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem`, width: '100%' }}
        className="topimg magnify"
      />
      </div>
      <div className>
      <p className="font-bold text-base">ONE Dining Table<br />Design: Jin Kuramoto</p>
        <p className="leading-8">We’re pleased to announce an additional two sizes to the range of circular tables developed in conjunction with designer Jin Kuramoto. The aim has been to create a piece that attracts you at first sight, then convinces you when you look closer and touch the item. The new tables can be set up in a variety of physical spaces, while still maintaining a single leg, despite their horizontal length. In this way, they offer a wide range of options for dining, meetings, and other occasions.</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3 sm:gap-2">
      <div className="overflow-hidden"><StaticImage
        src="../images/one1.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/one2.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/one3.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
    </div>
    <p className="text-center"><a href="https://www.condehouse.co.jp/products/detail.php?product_id=714" target="_blank" className="ch-button shadow-lg">→ Detail</a></p>
    {/* one section ends */}
    
    <div className="overflow-hidden" >
      <div className="flex overflow-hidden">
        <StaticImage
          src="../images/02.jpg"
          alt="CondeHouse New System Furniture"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
        <hr />
        <StaticImage
          src="../images/ten.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
      </div>

      <div class="p-6 sm:p-10 leading-8">
        <p>The new SL table (which went on sale this Spring) and additional items from the popular TEN series, are also available. These provide options for a variety of scenarios, from living areas to office spaces.</p>
      </div> 
        {/* factory tour */}
        <div class="overflow-hidden">
        <StaticImage
            src="../images/factorytour.jpg"
            alt="CondeHouse Style 2021 Scene Image 1"
            style={{ marginBottom: `1.45rem` }}
            className="topimg magnify"
          />
        <div class="p-6 sm:p-10 shadow-2xl leading-8" id="factorytour">
          <p className="text-lg font-bold">Virtual Factory Tour</p>
          <p>Our video presentation shows the complete production process of the popular CondeHouse Runt Om chair. From ‘sawing and veneer processing’ to the final process of ‘seat board installation’ and ‘pre-shipment inspection’. We hope you will enjoy watching the dynamic manufacturing</p>
          <iframe width="100%" height="400" src="https://www.youtube.com/embed/MucLe7HK2RY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <div class="grid grid-cols-2 factory-links">
          <div>
            <ul>
              <li><a href="https://youtu.be/piYRHoMNlms" target="_blank">①Factory</a></li>
              <li><a href="https://youtu.be/RxdpkhV_KeM" target="_blank">②Sawing/Veneer Making</a></li>
              <li><a href="https://youtu.be/47pydPi3W_M" target="_blank">③Rift Cutting</a></li>
              <li><a href="https://youtu.be/FBoilRYvRBs" target="_blank">④Processing(Beam, Arm, Leg)</a></li>
              <li><a href="https://youtu.be/KnaoE_xLCMA" target="_blank">⑤Processing(Leg)</a></li>
            </ul>
          </div>
          <div>
            <ul>
              <li><a href="https://youtu.be/MPrQJ9FLvyg" target="_blank">⑥Finishing(Sanding/Assembly)</a></li>
              <li><a href="https://youtu.be/ZKdsJoTs0Cw" target="_blank">⑦Finishing(Assembly/Sanding)</a></li>
              <li><a href="https://youtu.be/muvhXs_IUdE" target="_blank">⑧Painting</a></li>
              <li><a href="https://youtu.be/JDH6SZlSrjc" target="_blank">⑨Seating(Fabric Stapling)</a></li>
              <li><a href="https://youtu.be/BaTEH4Ozv_o" target="_blank">⑩Finishing Process(Seat Attachment/Inspection)</a></li>
            </ul>
          </div>
         </div>
        </div>
        <div class="p-6 sm:p-10 leading-8">
         <p className="text-lg font-bold">CondeHouse Style 2021 Official App</p>
         <div className="sm:grid sm:grid-cols-2 sm:gap-5">
          <div class="overflow-hidden">
            <StaticImage
                src="../images/ios.png"
                alt="CondeHouse Style 2021 Scene Image 1"
                style={{ marginBottom: `1.45rem`, maxWidth: `250px`  }}
                className="topimg magnify"
              />
          </div>
          <div>
            <p>In conjunction with ‘CondeHouse Style 2021 June Online’, we are releasing the official CondeHouse app. In addition to the convenience of having CondeHouse Style 2021 available at your fingertips, the app will use a notification system to keep you updated with CondeHouse news and events. </p>
            <p class="text-right"><a href="https://apps.apple.com/jp/app/ch-style/id1561572259" class="ch-button" target="_blank">Download from App Store</a></p>
          </div>
         </div>
         
        </div>
       
      </div>
    </div>
  </Layout>
)

export default IndexPage
